<template>
  <OnboardingStep
    :title="$t('onboarding.quickbooksIntroView.title')"
    :next-text="$t('onboarding.quickbooksIntroView.nextText')"
    v-bind="$props"
  >
    <I18nArray tag="p" path="onboarding.quickbooksIntroView.paragraphs" />
    <RaiLink :to="{ name: 'onboarding.automations.quickbooks.setup' }">
      {{ $t("onboarding.quickbooksIntroView.connectQuickbookslink") }}
    </RaiLink>
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import I18nArray from "@/components/I18nArray";
import { RaiLink } from "@/core-ui";
export default {
  name: "QuickbooksIntroView",
  components: { I18nArray, RaiLink },
  extends: BaseStepView,
};
</script>
